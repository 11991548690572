import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
} from "react-admin";

function ProfileList() {
  return (
    <List>
      <Datagrid>
        <EditButton />
        <TextField source="id" />
        <TextField source="profile" />
        <TextField source="topic" />
        <TextField source="title" />
        <TextField source="key" />
        <TextField source="bit" />
        <TextField source="unit" />
      </Datagrid>
    </List>
  );
}

export default ProfileList;
