import React from "react";
import { Edit, SimpleForm, PasswordInput } from "react-admin";

function UserProfileEdit() {
  return (
    <Edit>
      <SimpleForm>
        <PasswordInput source="password" />
      </SimpleForm>
    </Edit>
  );
}

export default UserProfileEdit;
