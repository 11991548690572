import React from "react";
import {
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
  BooleanInput,
} from "react-admin";
import { ColorInput } from "react-admin-color-picker";

function ProfileCreate() {
  return (
    <Create>
      <SimpleForm>
        <TextInput source="profile" />
        <TextInput source="title" />
        <TextInput source="topic" />
        <TextInput source="key" />
        <TextInput source="bit" />
        <TextInput source="unit" />
        <TextInput source="order" />
        <TextInput source="writeTopic" />
        <TextInput source="write" />
        <TextInput source="decimals" />
        <TextInput source="group" />
        <SelectInput
          source="display"
          choices={["TEXT", "INDICATOR", "SWITCH", "BUTTON"].map((v) => ({
            id: v,
            name: v,
          }))}
        />
        <ColorInput source="colorOff" />
        <ColorInput source="colorOn" />
        <BooleanInput source="showOnZero" />
      </SimpleForm>
    </Create>
  );
}

export default ProfileCreate;
