import React from "react";
import { List, Datagrid, TextField, DateField, EditButton } from "react-admin";

function UserList() {
  return (
    <List>
      <Datagrid>
        <EditButton />
        <TextField source="id" />
        <TextField source="username" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="phoneNumber" />
        <TextField source="email" />
        <TextField source="address" />
        <DateField
          source="createdAt"
          transform={(value: Date) => new Date(value.toString().slice(0, -7))}
        />
        <DateField
          source="expirationDate"
          transform={(value: Date) => new Date(value.toString().slice(0, -7))}
        />
      </Datagrid>
    </List>
  );
}

export default UserList;
