import React, { useEffect, useState } from "react";
import { Admin, Resource, fetchUtils } from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import PeopleIcon from "@mui/icons-material/People";
import TypeSpecimenIcon from "@mui/icons-material/TypeSpecimen";
import authProvider from "./authProvider";
import socket from "./socket";
import Dashboard from "./components/Dashboard";
import UserList from "./components/UserList";
import UserCreate from "./components/UserCreate";
import UserEdit from "./components/UserEdit";
import ProfileList from "./components/ProfileList";
import ProfileCreate from "./components/ProfileCreate";
import ProfileEdit from "./components/ProfileEdit";
import UserProfileList from "./components/UserProfileList";
import UserProfileEdit from "./components/UserProfileEdit";

const httpClient = (url: any, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

function App() {
  const [isAdmin, setAdmin] = useState(false);
  useEffect(() => {
    function onConnect() {
      console.log("connected!");
    }

    function onDisconnect() {}

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
    };
  });

  return (
    <Admin
      dataProvider={jsonServerProvider(
        process.env.REACT_APP_PROVIDER_URL || "",
        httpClient
      )}
      authProvider={(type, params) => authProvider(type, params, setAdmin)}
      dashboard={() => <Dashboard />}
    >
      {isAdmin ? (
        <>
          <Resource
            name="users"
            list={UserList}
            create={UserCreate}
            edit={UserEdit}
            icon={PeopleIcon}
          />
          <Resource
            name="profiles"
            list={ProfileList}
            create={ProfileCreate}
            edit={ProfileEdit}
            icon={TypeSpecimenIcon}
          />
        </>
      ) : (
        <Resource
          name="users"
          options={{ label: "Setting" }}
          list={UserProfileList}
          edit={UserProfileEdit}
        />
      )}
    </Admin>
  );
}

export default App;
