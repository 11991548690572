import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from "react-admin";
import socket from "./socket";
import { SetStateAction } from "react";

async function authProvider(
  type: string,
  params: { username: any; password: any; status: any },
  setAdmin: { (value: SetStateAction<boolean>): void; (arg0: boolean): void; }
) {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(
      (process.env.REACT_APP_PROVIDER_URL || "") + "/users/login",
      {
        method: "POST",
        body: JSON.stringify({ username, password }),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );
    const response = await fetch(request);
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
    const { token } = await response.json();
    localStorage.setItem("token", token);
    setAdmin(username === "admin");
    socket.emit("login", { token });
    return Promise.resolve();
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem("token");
    setAdmin(false);
    socket.emit("logout");
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    const status = params.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  }
  return Promise.resolve();
}

export default authProvider;
