import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useGetList } from "react-admin";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import socket from "../socket";

function handleProfile(profile: any) {
  return profile.display === "TEXT" ? (
    <TextField
      {...(!profile.showOnZero ? { style: { display: "none" } } : {})}
      key={profile.id}
      label={profile.title}
      id={"p" + profile.id.toString()}
      sx={{ m: 1, width: "25ch" }}
      InputProps={{
        startAdornment: <InputAdornment position="start"></InputAdornment>,
        endAdornment: (
          <InputAdornment position="end">{profile.unit}</InputAdornment>
        ),
      }}
      variant="outlined"
      disabled={!profile.write}
      onChange={(e) => {
        e.preventDefault();
        socket.emit("msg", {
          id: profile.id,
          value: +e.currentTarget.value,
        });
      }}
    />
  ) : profile.display === "INDICATOR" ? (
    <Button
      {...(!profile.showOnZero ? { style: { display: "none" } } : {})}
      key={profile.id}
      id={"p" + profile.id.toString()}
      sx={{ m: 1, width: "25ch" }}
      variant="contained"
      disabled
    >
      {profile.title}
    </Button>
  ) : profile.display === "SWITCH" ? (
    <Button
      {...(!profile.showOnZero ? { style: { display: "none" } } : {})}
      key={profile.id}
      id={"p" + profile.id.toString()}
      sx={{ m: 1, width: "25ch" }}
      variant="contained"
      onClick={(e) => {
        e.preventDefault();
        socket.emit("msg", {
          id: profile.id,
          value: +!+(e.currentTarget.getAttribute("data-value") || 0),
        });
      }}
    >
      {profile.title}
    </Button>
  ) : (
    <Button
      {...(!profile.showOnZero ? { style: { display: "none" } } : {})}
      key={profile.id}
      id={"p" + profile.id.toString()}
      sx={{ m: 1, width: "25ch" }}
      variant="contained"
      onClick={(e) => {
        e.preventDefault();
        socket.emit("msg", {
          id: profile.id,
          value: 1,
        });
      }}
    >
      {profile.title}
    </Button>
  );
}

function Dashboard() {
  const { isLoading, data } = useGetList("profiles", {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "order", order: "ASC" },
  });

  const { data: users } = useGetList("users");

  useEffect(() => {
    function onMsgEvent(msgData: any) {
      // [{id,value}]
      const ids = msgData.map((item: any) => item.id);
      const profiles = data?.filter((profile: any) => ids.includes(profile.id));
      for (const profile of profiles || []) {
        const element = document.querySelector(
          "#p" + profile.id
        ) as HTMLFormElement;
        const { value } = msgData?.find((item: any) => item.id === profile.id);
        element.setAttribute("data-value", value);
        if (!profile.showOnZero && !value)
          element?.parentElement?.parentElement?.setAttribute(
            "style",
            "display:none;"
          );
        else element.parentElement?.parentElement?.removeAttribute("style");
        switch (profile.display) {
          case "TEXT":
            if (document.activeElement !== element) element.value = value;
            break;
          case "INDICATOR":
          case "SWITCH":
            element.setAttribute(
              "style",
              `background-color:${
                value ? profile.colorOn : profile.colorOff
              };${element?.parentElement?.parentElement?.getAttribute("style")}`
            );
            break;
        }
      }
    }

    socket.on("msg", onMsgEvent);

    return () => {
      socket.off("msg", onMsgEvent);
    };
  }, [data]);

  return (
    <Card>
      <CardHeader title="Welcome to the administration" />
      <CardContent>
        {!users?.find((u) => u.username === "admin") &&
          users?.[0]?.expirationText && (
            <Alert severity="info">{users?.[0]?.expirationText}</Alert>
          )}
        {!isLoading &&
          data?.filter((profile) => !profile.group).map(handleProfile)}
        {data
          ?.filter((profile) => profile.group)
          ?.map((profile) => profile.group)
          .filter((item, pos, arr) => arr.indexOf(item) === pos)
          .map((group) => (
            <Accordion key={Math.random()}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{group}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {data
                  ?.filter((profile) => profile.group === group)
                  ?.map(handleProfile)}
              </AccordionDetails>
            </Accordion>
          ))}
      </CardContent>
    </Card>
  );
}

export default Dashboard;
