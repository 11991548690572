import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  PasswordInput,
  DateInput,
} from "react-admin";

function UserEdit() {
  return (
    <Edit>
      <SimpleForm>
        <TextInput disabled source="id" />
        <TextInput source="username" />
        <PasswordInput source="password" />
        <TextInput source="firstName" />
        <TextInput source="lastName" />
        <TextInput source="phoneNumber" />
        <TextInput source="email" />
        <TextInput source="address" />
        <TextInput source="topic" />
        <TextInput source="profile" />
        <TextInput source="description" />
        <DateInput source="expirationDate" />
        <TextInput source="expirationText" />
      </SimpleForm>
    </Edit>
  );
}

export default UserEdit;
